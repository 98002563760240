import * as React from "react";
import Layout from "../../components/layout";
import FromThePrincipalContent from "../../components/about/fromtheprincipalContent";
import BannerImage from "../../components/master/BannerImage";
import heroImage from "../../assets/about/sr-margaret-hero.jpg";
import ContentWrapper from "../../components/master/ContentWrapper";

const FromThePrincipalPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="from the principal" />

      <FromThePrincipalContent />
    </Layout>
  );
};

export default FromThePrincipalPage;
