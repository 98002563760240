import React from "react";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import SideBar from "../master/SideBar";
import {about} from "../const";
import playground from "../../assets/about/principals_strategic_intent.jpg"

const FromThePrincipalContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="Executive Principal’s message - Dr Margaret Ghosn mshf"/>

                <p>A warm welcome to our valued students, parents, staff and stakeholders.
                </p>
                <br/>

                <p>
                    As we travel through 2024 we pray that the God of Love will be palpable in all our decision making
                    so that the year will be one of flourishment for all.
                </p>

                <p>
                    Our focus is and will always be on the holistic care of our students.
                </p>

                <p>
                    Nourishment of the students’ spiritual life is a priority with prayer and sacramental celebrations a
                    key focus along with pastoral care sessions and retreat days.
                </p>

                <p>
                    The students’ growth intellectually can only be nourished when they are engaged and challenged in
                    class. Teachers have been undertaking continuous professional development in order to engage
                    learners to think critically and relish the gaining of knowledge.
                </p>
                <p>
                    In the aspect of wellbeing, students are encouraged to participate in numerous extracurricular
                    activities ranging from rep sport to leadership roles. Our opportunities for extracurricular
                    activities are constantly expanding to provide students with as many varied experiences as possible.
                </p>
                <p>
                    The life of the College relies heavily on the positive interaction between the Executive Principal,
                    College Chaplain, Governing Board, Executive team, Coordinators, teachers and staff, students,
                    parents and stakeholders. When we support each other we enable one another to achieve greater
                    things.
                </p>
                <p>
                    May the gift of the Holy Spirit enlighten us as we travel together on the journey of learning and
                    growth.
                </p>
                <p>
                    Sr Margaret Ghosn
                </p>
                <p>
                    Principal, MSHF
                </p>

            </div>
            <SideBar items={about} title="About Us"/>
        </ContentWrapper>
    </div>
);

export default FromThePrincipalContent;
